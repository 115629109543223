<template>
  <div class="word-cloud">
    <slot />
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  export default Vue.extend({});
</script>
<style scoped lang="less">
.word-cloud {
  span{
    margin-right: 0.5em;
  }
}
.word-cloud span:nth-child(5n){
  color: darken(#53c7e4, 10%);
}
.word-cloud span:nth-child(5n+1){
  color: darken(#ceacf5, 10%);
}
.word-cloud span:nth-child(5n+2){
  color: darken(#fa9ec5, 10%);
}
.word-cloud span:nth-child(5n+3){
  color: darken(#fc7a7a, 10%);
}
.word-cloud span:nth-child(5n+4){
  color: darken(#fca87e, 10%);
}
</style>
