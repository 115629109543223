<template>
  <main>
    <div class="above-fold">
      <div class="app-width">
        <div class="logo">
          <img src="/img/storyfolder-logo.svg">
          <span class="storyfolder">
            StoryFolder<span class="pro">Beta</span>
          </span>
          <span class="subtitle">
            Storyboards and shot lists
            from any video
          </span>
        </div>
        <br />
        <br />

        <div>
          <div class="app">
            <SmoothReflow>
              <div v-show="!video">
                <ul class="selector">
                  <li :class="{selected: sourceType==='url'}" @click="sourceType='url'">
                    Convert YouTube Video
                  </li>
                  <li :class="{selected: sourceType==='upload'}" @click="sourceType='upload'">
                    Convert Video File
                  </li>
                </ul>
                <br />
                <br />
                <div v-if="sourceType==='upload'">
                  <file-upload
                      class="file-upload"
                      post-action="/api/videos"
                      accept="video/mp4,video/quicktime,video/mpeg,video/x-msvideo,application/octet-stream,application/mxf,video/webm"
                      extensions="mp4,mov,mpg,mpeg,webm,avi,mxf"
                      :multiple="false"
                      :size="maxFileSize"
                      :data="{sourceType: 'upload'}"
                      v-model="files"
                      @input-filter="inputFilter"
                      @input-file="inputFile"
                      ref="upload"
                  >
                    <button v-if="!isFileSelected">
                      <IconSvg name="arrow" class="rotate-180" style="font-size: 2rem" v-if="false"/>
                      Select Video File
                    </button>
                    <h3 v-else>
                      {{files[0].name}}
                    </h3>
                  </file-upload>
                </div>
                <div v-if="sourceType==='url'">
                  <p v-if="showUrlError">Enter a valid YouTube or Vimeo URL</p>
                  <input type="text" v-model="videoUrl" placeholder="Paste a YouTube or Vimeo URL">
                </div>

                <SmoothReflow>
                  <div class="form-options">
                    <div class="form-option">
                      <label>Storyboard</label>
                      <toggle-button v-model="settings.storyboard.enabled" :labels="{checked: 'On', unchecked: 'Off'}" :width="60" :color="switchSettings.switchColors" sync/>
                    </div>
                    <div class="form-option">
                      <label>Images</label>
                      <toggle-button v-model="settings.images.enabled" :labels="{checked: 'On', unchecked: 'Off'}" :width="60" :color="switchSettings.switchColors" sync/>
                    </div>
                    <div class="form-option">
                      <label>Shot List</label>
                      <toggle-button v-model="settings.timecode.enabled" :labels="{checked: 'On', unchecked: 'Off'}" :width="60" :color="switchSettings.switchColors" sync/>
                    </div>
                    <div class="form-option">
                      <label>Clips</label>
                      <toggle-button v-model="settings.clips.enabled" :labels="{checked: 'On', unchecked: 'Off'}" :width="60" :color="switchSettings.switchColors" sync/>
                      <SmoothReflow>
                        <div class="subsection" v-if="settings.clips.enabled">
                          <p>
                            <input type="radio" name="speed" value="slow" v-model="settings.clips.speed" id="speed-slow">
                            <label for="speed-slow">MP4 (Medium quality, accurate timing)</label>
                          </p>
                          <p>
                            <input type="radio" name="speed" value="fast" v-model="settings.clips.speed" id="speed-fast">
                            <label for="speed-fast">MKV (Original quality, less accurate timing)</label>
                          </p>
                        </div>
                      </SmoothReflow>
                    </div>
                    <div class="form-option">
                      <label>Script</label>
                      <toggle-button v-model="settings.script.enabled" :labels="{checked: 'On', unchecked: 'Off'}" :width="60" :color="switchSettings.switchColors" sync/>
                      <SmoothReflow>
                        <div v-if="settings.script.enabled" class="two-column half">
                          <div class="column subsection">
                            <h3>Language</h3>
                            <p>
                              <select v-model="settings.script.language">
                                <option value="auto">Auto-Detect</option>
                                <option v-for="language in languagesSorted" :value="language.value" :key="language.value">{{language.label}}</option>
                              </select>
                            </p>
                          </div>
                          <div class="column subsection">
                            <h3>Speaking characters</h3>
                            <p>
                              <select v-model="settings.script.characters">
                                <option value="10">Auto-Detect</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>6</option>
                                <option>7</option>
                                <option>8</option>
                                <option>9</option>
                                <option>10</option>
                              </select>
                            </p>
                          </div>
                        </div>
                      </SmoothReflow>
                    </div>
                  </div>
                </SmoothReflow>
                <p>
                  <span v-if="showEmailError">Enter a valid email address</span>
                  <input v-model="email" type="text" placeholder="Your Email Address">
                </p>
                <p v-if="usePro">
                  <span v-if="showKeyError">Enter a valid Pro Key</span>
                  <input v-if="usePro" v-model="apiKey" type="text" placeholder="Enter your Pro Key">
                </p>
                <p>
                  <button @click="upload">Start</button>
                </p>
                <p class="center">
                  We'll email you when your video is finished processing.
                </p>
                <p v-if="!usePro" class="center pro-only">
                  <a href="#" @click.prevent="usePro=true">Enter Pro Key</a>
                  or
                  <a href="#" @click.prevent="$emit('checkout')">Get Pro Key</a>
                </p>
              </div>
              <div v-if="video" style="text-align: center">
                <h2>{{title}}</h2>
                <h3 class="meta">{{video.name}}</h3>
                <div v-if="video.status === 'Complete'">
                  <button @click="download">
                    Download
                  </button>
                  <br />
                  <br />
                  <div class="storyboard" v-if="false">
                    <div v-for="index in Math.min(video.shots - 1, 6)" class="panel" :key="index">
                      <div class="shot-number">Shot No. {{index}}</div>
                      <img :src="`/api/videos/${video.id}/shots/${index}.jpg`" />
                    </div>
                  </div>
                </div>
                <div  v-if="!['Complete','Error'].includes(video.status)">
                  <p>
                    We'll email {{email}} when the it's done.
                    <span v-if="false">In the meantime, vote on which features get developed next.</span>
                    <br />
                  </p>
                  <p v-if="false">
                    <a class="button" href="https://storyfolder.featureupvote.com" target="_blank">
                      Vote Now
                    </a>
                  </p>
                </div>
                <div v-if="video.status === 'Error'">
                  <p v-if="video.errorMessage.toLowerCase().includes('upgrade')" style="text-align: left">
                    Although all features are free to use, there are limitations to file size and and video length to keep server costs under control.
                    <br />
                    <br />
                    You can remove these limitations by <a href="#" @click.prevent="$emit('checkout')">getting a <span class="pro">Pro</span> key</a> or just split or compress your videos to make them smaller.
                    <br />
                    <br />
                  </p>

                  <a v-if="video.errorMessage.toLowerCase().includes('upgrade')" href="#" @click.prevent="$emit('checkout')" class="button pro-only">Upgade Now</a>
                  &nbsp;
                  <a href="#" @click.prevent="resetForm" class="button secondary">Okay</a>
                  <br />
                  <br />
                </div>
              </div>
            </SmoothReflow>
          </div>
          <br />
          <br />
          <br />
          <br />
          <h1 class="center">Community Stats</h1>
          <br />
          <div class="metrics" v-if="metrics">
            <div class="metric">
              <label>Storyboards Generated</label>
              {{metrics.counts.complete.toLocaleString()}}
            </div>
            <div class="metric">
              <label>Shots Detected</label>
              {{metrics.metrics.shots.toLocaleString()}}
            </div>
            <div class="metric">
              <label>Images Generated</label>
              {{metrics.metrics.images.toLocaleString()}}
            </div>
            <div class="metric">
              <label>Video<br />Processed</label>
              {{metrics.metrics.hours.toLocaleString()}} hrs
            </div>
            <div class="metric">
              <label>Failure<br />Rate</label>
              {{metrics.metrics.failRate}}%
            </div>
          </div>
          <router-link :to="{name:'status'}" class="banner online">
            Server Status: <span class="status">Online</span>
          </router-link>
          <br />
          <br />
          <br />
        </div>

      </div>
    </div>


    <section class="app-width">
      <br />
      <br />
      <br />
      <br />
      <br />
      <h2>
        For filmmakers, cinematographers, editors, VFX producers, and visual storytellers, StoryFolder is a comprehensive toolset for deconstructing videos and films for production workflows, planning, and research.
      </h2>

    <br />
    <br />
    <br />
    <br />
    <div class="app-width">
      <div class="video-container">
        <iframe
          src="//www.youtube.com/embed/N68URVJ5RvI?rel=0&modestbranding=1&autohide=1&showinfo=0"
          frameborder="0"
          allowfullscreen
          class="video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </div>
    </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <h1>
        All features of StoryFolder are free to use for the beta community.
      </h1>
      <h3>
        The only limitations for beta users are that videos are limited 1GB in size
        and 15 minutes in length, and 50 shots total. This helps keep video processing
        server costs managable, which helps a lot considering I'm personally paying the
        server bill to keep StoryFolder online for the community.
        <br />
        <br />

        If you need to process larger, longer videos or you want support the community you can remove these limitations by <a href="#" @click.prevent="$emit('checkout')">getting a <span class="pro">Pro</span> key</a>.
      </h3>
    </section>
    <br />
    <br />
    <br />


    <a name="samples" />
    <br />
    <br />
    <br />
    <section class="app-width examples">
      <H1 class="center">It breaks down any video into this:</H1>
      <div class="two-column half ">
        <div class="column">
          <h2>Storyboard</h2>
          <br />
          <img src="/img/samples/moana/storyboard.png" width="100%" loading="lazy"/>
        </div>
        <div class="column">
          <h2>Script</h2>
          <br />
          <img src="/img/samples/moana/script.png" width="100%" loading="lazy"/>
        </div>
      </div>
      <h2>Shot List</h2>
      <br />
      <img src="/img/samples/moana/shotlist.png" width="100%" loading="lazy"/>
      <div class="two-column half ">
        <div class="column">
          <h2>Images</h2>
          <br />
          <img src="/img/samples/moana/images.png" width="100%" loading="lazy"/>
        </div>
        <div class="column">
          <h2>Clips</h2>
          <br />
          <img src="/img/samples/moana/clips.png" width="100%" loading="lazy"/>
        </div>
      </div>

    </section>


    <section class="app-width">
    </section>
    <section class="app-width testimonial-cloud">
      <h2>Friends at Apple, Netflix, Showtime, Sony, and others said:</h2>
      <h2>
        <WordCloud>
          <span>I can storyboard automatically and work much faster with StoryFolder.</span>
          <span>With StoryFolder I see the story clearly and edit more efficiently.</span>
          <span>StoryFolder saves me endless time creating screengrabs and shot lists.</span>
          <span>StoryFolder helps me break down shots for bidding and VFX workflows.</span>
          <span>StoryFolder is an awesome tool for any visual storyteller.</span>
          <span>StoryFolder is amazing! It opens up a whole new way of visualizing productions.</span>
          <span>It's just freakin' cool to check out how my fav editors have put scenes together.</span>
          <span>I am quite surprised by the possibilities.</span>
          <span>StoryFolder is fantastic! Such a time-saver.</span>
          <span>I was able to make a beautiful contact sheet from our short film in seconds.</span>
          <span>Far, far, far quicker than me doing the same job maually.</span>
          <span>Each tool is really useful.</span>
          <span>Intuitive and genius.</span>
          <span>StoryFolder is a masterpiece.</span>
          <span v-if="false">Amazing tool. Easier than any other way.</span>
          <span v-if="false">It works like a charm. Such a great tool. Just a big value.</span>
          <span>The most innovative filmmaking tool I've seen in a long time.</span>
          <span>This will be an industry standard in no time.</span>
        </WordCloud>
      </h2>
    </section>
    <a name="features" />
    <br />
    <br />
    <br />
    <br />

    <section class="section-1 app-width">
      <h1 class="center">
        Deconstruct videos<br />
        Automatically
      </h1>
      <br /><br />
      <div class="feature-tags">
        <span>Storyboard</span>
        <span>Images</span>
        <span>Clips</span>
        <span>Script</span>
        <span>Shot List</span>
        <div v-if="false">
          <span>Contact Sheet</span>
          <span>Color Pallette</span>
          <span>Statistics</span>
          <span>Subtitles</span>
          <span>Tags & Labels</span>
          <span>Keywords</span>
          <span>Synopsis</span>
          <span>Scenes</span>
          <span>Timecode</span>
        </div>
      </div>
      <br />
      <br />
      <div class="two-column half">
        <h1>
          Save time.<br />Like a lot.
        </h1>
        <div class="column">
          <p>
            Whether you're planning, pitching, bidding, producing, in distrubution, or post, StoryFolder has tools that save countless hours in any stage of production.
          </p>
        </div>
      </div>
      <br />
      <br />
      <div class="two-column half">
        <h1>
          Streamline<br />
          production.
        </h1>
        <div class="column">
          <p>
            Break down edits into storyboards, contact sheets, and detailed spreadsheets for easier communication and coordination with your production team.
          </p>
          <p>
            Generate shot lists with images, notes, and timecodes for easy understanding, documentation, or complex VFX & coloring workflows.
          </p>
        </div>
      </div>
      <br />
      <br />
      <div class="two-column half">
        <h1>
          Stay<br />
          Creative.
        </h1>
        <div class="column">
          <p>
            These processes are normally slow, manual, expensive tasks that when done by hand make your workflows tedious and costly.
            StoryFolder quickly and accurately automates these tasks so you can skip the tedium and focus on being creative.
          </p>
        </div>
      </div>
      <br />
      <br />
      <div class="two-column half">
        <h1>
          Research,<br />
          Anaylize,<br />
          Get Inspired.
        </h1>
        <div class="column">
          <p>
            Use StoryFolder in your research, creative and technical analysis, and create starting points for new projects.
          </p>
          <p>
            In love with Wes Anderson films or Star Wars? Run your favorite movie scenes and videos through StoryFolder to get an entirely new perspective on any production.
          </p>
        </div>
      </div>
    </section>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <div class="feature-list-container">
      <section class="feature-list app-width">
        <h1 class="center">
          Now every video is<br />
          a folder of assets.
        </h1>

        <br />

        <div class="two-column feature-dialogue">
          <div class="column">
            <h1 class="color-1">
              Shot List
            </h1>
            <h3>
              Automatically generate csv and xlsx spreadsheets
            </h3>
          </div>
          <div class="column">
            <p>
             Automatically generate spreadsheets containing shot information, filenames, timecode, frame numbers and embedded images for visual reference. Multiple formats are compatible with Excel, Google Sheets, can be important into your NLE, and are compatible with industry tools like ffmpeg.
            </p>
          </div>
        </div>

        <div class="two-column">
          <div class="column">
            <h1 class="color-2">
              Storyboard
            </h1>
            <h3>
              Automatically generate printable storyboards
            </h3>
          </div>
          <div class="column">
            <p>
              Storyboards are automatically generated with the most relevant images and metadata from each video.
              Download, print and share your storyboards.
            </p>
          </div>
        </div>

        <div class="two-column">
          <div class="column">
            <h1 class="color-3">
              Images
            </h1>
            <h3>
              Automatically detect shots and export the most relevant images
            </h3>
          </div>
          <div class="column">
            <p>
              Extract only the most relavent images from each clip. Images of the first, middle, and last frames of each clip are automatically exported in resolutions up to 8K.
            </p>
          </div>
        </div>
        <div class="two-column feature-clips">
          <div class="column">
            <h1 class="color-4">
              Clips
            </h1>
            <h3>
              Automatically split shots into separate clips
            </h3>
          </div>
          <div class="column">
            <p>
              Unique shots and clips are detected and split into separate files, named sequentially, and logged with industry standard SMPTE timecodes and timestamps for easy import into NLEs.
            </p>
          </div>
        </div>

        <div class="two-column feature-dialogue">
          <div class="column">
            <h1 class="color-5">
              Script
            </h1>
            <h3>
              Accurately transcribe dialogue to print on the page
            </h3>
          </div>
          <div class="column">
            <p>
             Dialogue is automatically converted to screenplay style scripts and srt subtitle files using advanced machine learning techniques. StoryFolder can even detect multiple speakers by vocal tone tagging them in each line of dialogue. PDF, HTML, Final Draft, Fountain and SRT formats included.
            </p>
          </div>
        </div>
      </section>
    </div>
    <a name="about" />
    <br />
    <br />
    <br />
    <section class="app-width from-jeff">
      <H1 class="center">
        From the guy who made it</H1>
      <h3 class="center">Wait, who's Jeff anyway?</h3>
      <p>
        My name is Jeff Jassky. I live in New York City with five great roommates, my girlfriend, and my adorable pet turtle Francine.
      </p>
      <p>
        Recently, I was working on some of the biggest projects of my career. I was poached by a premium video streaming service to move to New York and write code for their streaming video platform. The projects were interesting. There were millions of users, the team was talented, and I got to work in a fancy Midtown Manhattan office.
      </p>
      <p>
        It was considered to be a great job. Especially for being a home schooled, self-taught engineer.
      </p>
      <p>
        The problem was - I didn't actually like it. I kinda hated it.
      </p>
      <p>
        Every day began feeling the same. Creativity was generally frowned upon. My ideas didn't matter and weren't considered - which is fair enough considering the size of the company, but it didn't make me like it any better. Nights and weekends ended up being my creative time.
      </p>
      <p>
        I worked hard on side projects that inspired and excited me. I worked late into the nights, sometimes overnight, doing I loved - collaborating with friends and experimenting with new technologies.
      </p>
      <p>
        One day in my office cubicle I felt a change. It was time to leave. I had more energy to dedicate to the creative people and projects that I cared about. I left the company to work on creative projects full-time.
      </p>
      <p>
        StoryFolder was born in that time out of a love of filmmaking, production, and storytelling.
        With your support we can make all-new tools that make production workflows more joyful, more creative, and let you and your team focus on doing what you love.
      </p>
      <p>
        There are big ideas in the works for StoryFolder. You can help keep the beta online and free for everyone as I develop and release new feature requests from the community by <a href="https://patreon.com/storyfolder" target="_blank">Joining me on Patreon</a> or consider upgrading to <a href="#" @click.prevent="$emit('checkout')">StoryFolder Pro</a>.
      </p>
      <p>
        With the support of you and the rest of the Beta community, we'll build something great together.
      </p>
      <p>
        I appreciate you being part of it,
      </p>
      <p>
        <br />
        <img src="/img/jeff.jpg" width="125" style="border-radius: 1rem" loading="lazy"/>
        <br />
        <br />
        <strong>Jeff Jassky</strong>
      </p>
      <br />
      <br />
      <p style="text-align: center">
        <a class="button" href="https://patreon.com/storyfolder" target="_blank">
          Support StoryFolder on Patreon
          <IconSvg name="heart" />
        </a>
      </p>
    </section>
    <br />
    <br />
    <br />
    <br />
    <br />
    <footer>
      <div class="two-column half app-width">
        <div class="column center">
          <h2>
            What's next?
          </h2>
          <p>
            New features are being added regularly and there's a bigger project in the works. You can help by sharing feature ideas, reporting bugs, and sharing StoryFolder with your film making communities.
          </p>
          <br />
          <p>
            <a class="button" href="https://m.me/103443441596656?ref=w13593260" target="_blank">Stay Updated</a>
          </p>
        </div>
        <div class="column center pro-only">
          <h2>
            Buy a <span class="pro">Pro</span> Key
          </h2>
          <p>
            Upload larger files, process longer videos, get detailed timecode and shot lists and get full-length scripts.
          </p>
          <br />
          <p>
            <a class="button" href="#" @click.prevent="$emit('checkout')">Buy Pro Key</a>
          </p>
          <br />
          <br />
        </div>
      </div>
      <br />
      <br />
      <br />
      <div class="two-column half app-width">
        <div class="column center educators">
          <h2>
            Seeking Contributors
          </h2>
          <p>
            I need help! If you want to write an article about how to use StoryFolder for your particular production or education workflow I'd love to publish it.
          </p>
          <br />
          <p>
            <a class="button" href="mailto:jeff@jeffjassky.com" target="_blank">Become a Contributor</a>
          </p>
        </div>
        <div class="column center">
          <h2>
            Join the Patreon
          </h2>
          <p>
            Help keep StoryFolder beta online and free for everyone and support regular new feature development by joining me on Patreon.
          </p>
          <br />
          <p>
            <a class="button" href="https://patreon.com/storyfolder" target="_blank">Join the Patreon</a>
          </p>
          <br />
          <br />
        </div>
        <div class="column center">
          <h2>
            Vote on new features
          </h2>
          <p>
            I keep a live list of feature suggestions and let users like you vote on the next feature they'd like to see.
          </p>
          <br />
          <p>
            <a class="button" href="https://storyfolder.featureupvote.com" target="_blank">View Future Features</a>
          </p>
          <br />
          <br />
        </div>
      </div>
      <br />
      <div class="center">
        &copy; Copyright Jeff Jassky 2021. All rights reserved.
      </div>
    </footer>
  </main>
</template>

<script lang="ts">

import Vue from 'vue';
import FileUpload from 'vue-upload-component'
import IconSvg from "../components/atoms/IconSvg";
import SmoothReflow from "../components/atoms/SmoothReflow";
import WordCloud from "@/components/molecules/WordCloud.vue";
import ToggleButton from 'vue-js-toggle-button'
const axios = require('axios');

Vue.use(ToggleButton);

export default {
  name: 'App',
  components: {
    FileUpload,
    SmoothReflow,
    IconSvg,
    WordCloud
  },
  data() {
    return {
      files: [],
      isLive: false,
      liveInvertval: null,
      video: null,
      email: '',
      videoUrl: '',
      usePro: false,
      apiKey: '',
      sourceType:'upload',
      showEmailError: false,
      showKeyError: false,
      showUrlError: false,
      showOptions: false,
      metrics: null,
      languages: [
        {
          label: "Arabic (Gulf)",
          value: "ar-AE"
        },
        {
          label: "Arabic (Modern Standard)",
          value: "ar-SA"
        },
        {
          label: "Mandarin (Chinese)",
          value: "zh-CN"
        },
        {
          label: "Dutch",
          value: "nl-NL"
        },
        {
          label: "English (Australian)",
          value: "en-AU"
        },
        {
          label: "English (British)",
          value: "en-GB"
        },
        {
          label: "English (Indian)",
          value: "en-IN"
        },
        {
          label: "English (Irish)",
          value: "en-IE"
        },
        {
          label: "English (Scottish)",
          value: "en-AB"
        },
        {
          label: "English (US)",
          value: "en-US"
        },
        {
          label: "English (Welsh)",
          value: "en-WL"
        },
        {
          label: "Spanish",
          value: "es-ES"
        },
        {
          label: "Spanish (US)",
          value: "es-US"
        },
        {
          label: "French",
          value: "fr-FR"
        },
        {
          label: "French (Canadian)",
          value: "fr-CA"
        },
        {
          label: "Farsi",
          value: "fa-IR"
        },
        {
          label: "German",
          value: "de-DE"
        },
        {
          label: "German (Swiss)",
          value: "de-CH"
        },
        {
          label: "Hebrew",
          value: "he-IL"
        },
        {
          label: "Hindi (Indian)",
          value: "hi-IN"
        },
        {
          label: "Indonesian",
          value: "id-ID"
        },
        {
          label: "Italian",
          value: "it-IT"
        },
        {
          label: "Japanese",
          value: "ja-JP"
        },
        {
          label: "Korean",
          value: "ko-KR"
        },
        {
          label: "Malay",
          value: "ms-MY"
        },
        {
          label: "Portuguese",
          value: "pt-PT"
        },
        {
          label: "Portuguese (Brazilian)",
          value: "pt-BR"
        },
        {
          label: "Russian",
          value: "ru-RU"
        },
        {
          label: "Tamil",
          value: "ta-IN"
        },
        {
          label: "Telugu",
          value: "te-IN"
        },
        {
          label: "Turkish",
          value: "tr-TR"
        }
      ],
      settings: {
        storyboard: {
          enabled: true
        },
        clips: {
          enabled: false,
          speed: 'fast'
        },
        script: {
          enabled: false,
          language: 'auto',
          characters: "10"
        },
        images: {
          enabled: false
        },
        timecode: {
          enabled: false
        }
      },
      switchSettings: {
        switchColors: {
          checked: '#4f4fff',
          unchecked: '#444444'
        }
      }
    }
  },
  computed: {
    isFileSelected(){
      return this.files.length > 0
    },
    isEmailValid(){
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.email).toLowerCase());
    },
    isKeyValid(){
      const re = /[A-Z0-9]{7}-[A-Z0-9]{7}-[A-Z0-9]{7}-[A-Z0-9]{7}/
      return re.test(String(this.apiKey).toUpperCase().trim());
    },
    title(){
      if(!this.video){
        return 'Try It';
      }else{
        if(this.video.status === 'Complete') {
          return 'Ready to Download';
        }else if(this.video.status === 'Error') {
          return this.video.errorMessage;
        }else{
          return `${this.video.status} ${!isNaN(this.video.progress) ? `${this.video.progress}%` : ''}`;
        }
      }
    },
    headers(){
      return {
        'X-API-KEY': this.apiKey
      }
    },
    languagesSorted(){
      return [...this.languages].sort(function(a, b) {
         return a.label.localeCompare(b.label);
      })
    },
    maxFileSize(){
      if(this.usePro){
        return 1024 * 1024 * 1024 * 10; // 10GB
      }else{
        return 1024 * 1024 * 1024; // 1GB
      }
    }
  },
  watch: {
    video(){
      if(this.video.status === 'Complete' || this.video.status === 'Error'){
        this.stopLiveUpdates();
      }
    },
    apiKey(){
      if(this.files[0]){
        this.files[0].headers = this.headers
      }
    },
    settings: {
      handler(){
        localStorage.setItem('settings', JSON.stringify(this.settings))
      },
      deep: true
    },
    email(){
      localStorage.setItem('email', this.email)
    }
  },
  mounted(){
    this.restoreDefaults();
    this.loadStats();
  },
  methods: {
    restoreDefaults(){
      const email = localStorage.getItem('email');
      const settings = localStorage.getItem('settings');
      if(email){
        this.email = email;
      }
      if(settings){
        console.log('setting');
        Vue.set(this,'settings', JSON.parse(settings))
      }
    },
    loadStats(){
      axios.get('/api/analytics/public').then(results => {
        this.metrics = results.data;
      })
    },
    resetForm(){
      this.video = null;
      this.files = [];
    },
    upload(){
      this.showEmailError = false;
      this.showKeyError = false;

      if(!this.isEmailValid) {
        this.showEmailError = true;
        return;
      }

      if(this.usePro && !this.isKeyValid) {
        this.showKeyError = true;
        return;
      }

      if(this.sourceType === 'upload'){
        console.log(this.$refs.upload);
        this.files[0].data.email = this.email;
        this.files[0].data.settings = JSON.stringify(this.settings);
        this.$refs.upload.active = true;
      }else if(this.sourceType === 'url'){
        axios({
          url: `/api/videos`,
          method: 'post',
          data: {
            sourceType: 'url',
            email: this.email,
            videoUrl: this.videoUrl,
            settings: this.settings
          },
          headers: this.headers
        }).then(response => {
          if(response && response.data && response.data.id){
            this.video = response.data;
            this.startLiveUpdates();
          }
        })
      }
    },
    download(){
      dataLayer.push({
        event: 'storyboard_download',
        event_category: 'engagement'
      });
      window.location.href = `/api/videos/${this.video.id}/download`;
    },
    startLiveUpdates(){
      this.liveInvertval = setInterval(this.updateProgress.bind(this), 1000);
      this.isLive = true;
    },
    stopLiveUpdates(){
      clearInterval(this.liveInvertval);
      this.isLive = false;
    },
    updateProgress(){
      axios
        .get(`/api/videos/${this.video.id}`)
        .then(response => {
          if(response.data && response.data.id){
            this.video = response.data;
          }
        })
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // 添加文件前
        // Filter system files or hide files
        // 过滤系统文件 和隐藏文件
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }
        // Filter php html js file
        // 过滤 php html js 文件
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    },
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        // add
        console.log('add', newFile)
        dataLayer.push({
          event: 'video_select',
          'event_category': 'engagement'
        });
        this.files[0].headers = this.headers;
        window.location.hash = 'upload';
      }
      if (newFile && oldFile) {
        // update
        console.log('update', newFile);
        if(newFile.active){
          this.video = {
            status: 'Uploading',
            progress: newFile.progress
          }
        }
        if (!newFile.active && oldFile.active) {
          // Get response data
          console.log('response', newFile.response)
          if(newFile.response.id){
            dataLayer.push({
              event: 'video_upload_success',
              'event_category': 'engagement'
            });
            this.video = newFile.response;
            this.startLiveUpdates();
          }else{
            dataLayer.push({
              event: 'video_upload_failure',
              'event_category': 'engagement'
            });
          }
          if (newFile.xhr) {
            //  Get the response status code
            console.log('status', newFile.xhr.status)
          }
        }
        if(newFile.error){
          if(newFile.error == 'size'){
            this.video.status = 'Error';
            this.video.errorMessage = 'Upgrade to upload videos larger than 1GB.';
          }else{
            this.video.status = 'Error';
            this.video.errorMessage = newFile.error;
          }
        }

      }
      if (!newFile && oldFile) {
        // remove
        console.log('remove', oldFile)
      }
    }
  }
}
</script>

<style scoped lang="less">
  @import '../less/old';
</style>
